import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
} from "react";
import clsx from "clsx";
import { Doc, Id } from "../../convex/_generated/dataModel";
import { useQuery } from "convex/react";
import { api } from "../../convex/_generated/api";
import { MessageInput } from "./MessageInput";
import { Player } from "../../convex/aiTown/player";
import { Conversation } from "../../convex/aiTown/conversation";
import { Avatar } from "@mui/joy";
import { PlatformContext } from "../../contexts/PlatformContext";
import { TownContext } from "../../contexts/TownContext";
import { useMediaQuery } from "usehooks-ts";
import { useAudio } from "../../contexts/AudioContext";
import { Play, Pause } from "lucide-react";

type AudioQueueItem = {
  messageId: string;
  audioUrl: string;
  text: string;
};

interface Window {
  webkitAudioContext: typeof AudioContext;
}

const AudioButton = React.memo(
  ({ messageId, audioUrl }: { messageId: string; audioUrl: string }) => {
    const { currentAudio, playAudio, stopAudio } = useAudio();

    const isPlaying =
      currentAudio?.type === "conversation" &&
      currentAudio?.messageId === messageId;

    const handleClick = (e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();

      if (isPlaying) {
        console.log("Stopping audio:", messageId);
        stopAudio("conversation");
      } else {
        console.log("Playing audio:", messageId);
        playAudio(messageId, audioUrl, "conversation");
      }
    };

    return (
      <button
        onClick={handleClick}
        className="z-50 mt-2 px-2 py-1 text-xs text-white bg-transparent rounded hover:filter-brightness-125 flex items-center gap-1"
      >
        {isPlaying ? <Pause size={12} /> : <Play size={12} />}
        {isPlaying && "Playing"}
      </button>
    );
  }
);

export function Messages({
  focusedPlayer,
  worldId,
  engineId,
  conversation,
  inConversationWithMe,
  humanPlayer,
  scrollViewRef,
}: {
  focusedPlayer: Player;
  worldId: Id<"worlds">;
  engineId: Id<"engines">;
  conversation:
    | { kind: "active"; doc: Conversation }
    | { kind: "archived"; doc: Doc<"archivedConversations"> };
  inConversationWithMe: boolean;
  humanPlayer?: Player;
  scrollViewRef: React.RefObject<HTMLDivElement>;
}) {
  const context = useContext(PlatformContext);
  const townContext = useContext(TownContext);
  if (!townContext || !context) {
    throw new Error("TownContext not found");
  }
  const isMobile = useMediaQuery("(max-width: 768px)");
  const { username } = context;
  const { isSpectator } = townContext;
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [autoScroll, setAutoScroll] = useState(true);
  const humanPlayerId = humanPlayer?.id;
  const descriptions = useQuery(api.world.gameDescriptions, { worldId });
  const messages = useQuery(api.messages.listMessages, {
    worldId,
    conversationId: conversation.doc.id,
  });
  let currentlyTyping =
    conversation.kind === "active" ? conversation.doc.isTyping : undefined;
  if (messages !== undefined && currentlyTyping) {
    if (
      messages.find((m: any) => m.messageUuid === currentlyTyping!.messageUuid)
    ) {
      currentlyTyping = undefined;
    }
  }
  const currentlyTypingName =
    currentlyTyping &&
    descriptions?.playerDescriptions.find(
      (p: any) => p.playerId === currentlyTyping?.playerId
    )?.name;

  const scrollView = scrollViewRef.current;
  const isScrolledToBottom = useRef(false);

  useEffect(() => {
    const scrollView = scrollViewRef.current;
    if (!scrollView) return undefined;

    const onScroll = () => {
      const isAtBottom =
        Math.abs(
          scrollView.scrollHeight -
            scrollView.clientHeight -
            scrollView.scrollTop
        ) < 1;
      isScrolledToBottom.current = isAtBottom;
    };

    scrollView.addEventListener("scroll", onScroll);
    return () => scrollView.removeEventListener("scroll", onScroll);
  }, [scrollViewRef]);

  useEffect(() => {
    const scrollView = scrollViewRef.current;
    if (scrollView && (isScrolledToBottom.current || messages?.length === 1)) {
      setTimeout(() => {
        scrollView.scrollTop = scrollView.scrollHeight;
      }, 0);
    }
  }, [messages, currentlyTyping, scrollViewRef]);

  useEffect(() => {
    if (autoScroll) {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, currentlyTyping, autoScroll]);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    const isAtBottom = scrollHeight - scrollTop - clientHeight < 1;
    setAutoScroll(isAtBottom);
  };

  const { addToQueue, setActiveConversation } = useAudio();

  useEffect(() => {
    if (!messages || conversation.kind !== "active") return;

    messages.forEach((m: any) => {
      if (m.messageUuid && m.author !== humanPlayerId) {
        const audioUrl = `${process.env.REACT_APP_ASSETS_BUCKET}spots/${worldId}/${conversation.doc.id}/${m.messageUuid}.mp3`;
        addToQueue(conversation.doc.id, m.messageUuid, audioUrl, m.text);
      }
    });
  }, [messages, conversation.kind, humanPlayerId]);

  useEffect(() => {
    if (conversation.kind === "active" && inConversationWithMe) {
      setActiveConversation(conversation.doc.id, worldId);
    }

    return () => {
      setActiveConversation(null, worldId);
    };
  }, [conversation.doc.id, conversation.kind, inConversationWithMe]);

  if (messages === undefined) {
    return null;
  }

  if (messages.length === 0 && !inConversationWithMe) {
    return null;
  }

  const messageNodes: { time: number; node: React.ReactNode }[] = messages.map(
    (m: any, index: number) => {
      const isRightAligned = m.author !== focusedPlayer.id;
      const previousMessage = index > 0 ? messages[index - 1] : null;
      const isConsecutive =
        previousMessage && previousMessage.author === m.author;

      const node = (
        <div
          key={`text-${m._id}`}
          className={`items-center flex w-full ${isRightAligned ? "flex-row-reverse" : "flex-row"}`}
        >
          <div
            className={`space-y-1 flex flex-col flex-1 ${isRightAligned ? "items-end mr-2" : "items-start ml-2"}`}
          >
            {!isConsecutive && (
              <div
                className={`flex items-center ${isRightAligned ? "flex-row-reverse" : "flex-row"}`}
              >
                <Avatar
                  className={`!w-6 !h-6 md:!w-8 md:!h-8 ${isRightAligned ? "!ml-1 md:!ml-2" : "!mr-1 md:!mr-2"}`}
                  src={`${process.env.REACT_APP_ASSETS_BUCKET}${
                    descriptions?.playerDescriptions.find(
                      (p: any) => p.playerId === m.author
                    )?.character_id
                  }/display_picture.jpg`}
                />
                <span className="uppercase text-white font-main text-xs md:text-base">
                  {m.authorName}
                </span>
              </div>
            )}
            <div
              className={clsx(
                `p-3 md:p-4 rounded-xl w-fit max-w-[85%] md:max-w-[90%]`,
                isRightAligned ? "!bg-fourwall-orange" : "!bg-neutral-700",
                isConsecutive ? "mt-1" : "mt-2"
              )}
            >
              <div className="flex flex-col">
                <p className="rounded-lg font-chat text-sm md:text-base text-white !mx-0">
                  {m.text}
                </p>
                {/* {m.messageUuid && (
                  <div
                    className={`flex ${isRightAligned ? "justify-end" : "justify-start"}`}
                  >
                    <AudioButton
                      messageId={m.messageUuid}
                      audioUrl={`${process.env.REACT_APP_ASSETS_BUCKET}spots/${worldId}/${conversation.doc.id}/${m.messageUuid}.mp3`}
                    />
                  </div>
                )} */}
              </div>
            </div>

            <time
              dateTime={m._creationTime.toString()}
              className={`text-[0.5rem] text-white mx-2 ${isRightAligned ? "text-right" : "text-left"}`}
            >
              {new Date(m._creationTime).toLocaleString()}
            </time>
          </div>
        </div>
      );
      return { node, time: m._creationTime };
    }
  );

  const lastMessageTs = messages
    .map((m: any) => m._creationTime)
    .reduce((a: number, b: number) => Math.max(a, b), 0);

  const membershipNodes: typeof messageNodes = [];
  if (conversation.kind === "active") {
    for (const [playerId, m] of conversation.doc.participants) {
      const playerName = descriptions?.playerDescriptions.find(
        (p: any) => p.playerId === playerId
      )?.name;

      let started;
      if (m.status.kind === "participating") {
        started = m.status.started;
      }
      // console.log("started DEBUG", started);
      if (started) {
        // membershipNodes.push({
        //   node: (
        //     <div key={`joined-${playerId}`} className="leading-tight mb-3">
        //       <p className="text-white text-center">
        //         {playerName} joined the conversation.
        //       </p>
        //     </div>
        //   ),
        //   time: started,
        // });
      }
    }
  } else {
    for (const playerId of conversation.doc.participants) {
      const playerName = descriptions?.playerDescriptions.find(
        (p: any) => p.playerId === playerId
      )?.name;
      const started = conversation.doc.created;
      membershipNodes.push({
        node: (
          <div key={`joined-${playerId}`} className="leading-tight mb-3">
            <p className="text-white text-center" style={styles.joinLeave}>
              {playerName} joined the conversation.
            </p>
          </div>
        ),
        time: started,
      });
      const ended = conversation.doc.ended;
      membershipNodes.push({
        node: (
          <div key={`left-${playerId}`} className="leading-tight mb-3">
            <p className="text-white text-center" style={styles.joinLeave}>
              {playerName} left the conversation.
            </p>
          </div>
        ),
        // Always sort all "left" messages after the last message.
        // TODO: We can remove this once we want to support more than two participants per conversation.
        time: Math.max(lastMessageTs + 1, ended),
      });
    }
  }
  const nodes = [...messageNodes, ...membershipNodes];
  nodes.sort((a, b) => a.time - b.time);

  return (
    <div className="flex flex-col h-full">
      <div className="flex-grow overflow-y-auto mb-16" onScroll={handleScroll}>
        <div className="text-base sm:text-sm">
          <div className="text-black p-2">
            {nodes.length > 0 && nodes.map((n) => n.node)}
            {currentlyTyping && currentlyTyping.playerId !== humanPlayerId && (
              <div
                key="typing"
                className={`items-center flex ${
                  currentlyTyping.playerId !== focusedPlayer.id
                    ? "flex-row-reverse"
                    : "flex-row"
                }`}
              >
                <div
                  className={clsx(
                    "space-y-1 flex flex-col",
                    currentlyTyping.playerId !== focusedPlayer.id
                      ? "items-end mr-4"
                      : "items-start ml-4"
                  )}
                >
                  <div
                    className={`flex items-center ${
                      currentlyTyping.playerId !== focusedPlayer.id
                        ? "flex-row-reverse"
                        : "flex-row"
                    }`}
                  >
                    <Avatar
                      className={`!w-8 !h-8 ${
                        currentlyTyping.playerId !== focusedPlayer.id
                          ? "ml-2"
                          : "mr-2"
                      }`}
                      src={`${process.env.REACT_APP_ASSETS_BUCKET}${
                        descriptions?.playerDescriptions.find(
                          (p: any) => p.playerId === currentlyTyping?.playerId
                        )?.character_id
                      }/display_picture.jpg`}
                    />
                    <span className="uppercase text-white font-main">
                      {currentlyTypingName}
                    </span>
                  </div>
                  <div
                    className={clsx(
                      "w-[4/5] p-4 rounded-xl",
                      currentlyTyping.playerId !== focusedPlayer.id
                        ? "!bg-fourwall-orange"
                        : "!bg-neutral-700"
                    )}
                  >
                    <p className="rounded-lg font-chat text-white">
                      <img
                        className="typing"
                        src="/images/typing.gif"
                        alt="Typing..."
                      />
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div ref={messagesEndRef} />
      </div>
      {humanPlayer &&
        inConversationWithMe &&
        !isSpectator &&
        conversation.kind === "active" && (
          <div className="mt-auto">
            <MessageInput
              worldId={worldId}
              engineId={engineId}
              conversation={conversation.doc}
              humanPlayer={humanPlayer}
            />
          </div>
        )}
    </div>
  );
}

const styles: { [key: string]: React.CSSProperties } = {
  joinLeave: {
    fontFamily: "var(--font_b)",
    fontStyle: "italic",
    fontSize: "0.7rem",
    textAlign: "center",
    margin: "auto",
    color: "white",
  },
};
