import { ObjectType, v } from 'convex/values';
import { GameId, agentId, parseGameId } from './ids';

export class AgentDescription {
  agentId: GameId<'agents'>;
  identity: string;
  plan: string;
  convoPrompt: string | undefined;

  constructor(serialized: SerializedAgentDescription) {
    const { agentId, identity, plan, convoPrompt } = serialized;
    this.agentId = parseGameId('agents', agentId);
    this.identity = identity;
    this.plan = plan;
    this.convoPrompt = convoPrompt;
  }

  serialize(): SerializedAgentDescription {
    const { agentId, identity, plan, convoPrompt } = this;
    return { agentId, identity, plan, convoPrompt };
  }
}

export const serializedAgentDescription = {
  agentId,
  identity: v.string(),
  plan: v.string(),
  convoPrompt: v.optional(v.string()),
};
export type SerializedAgentDescription = ObjectType<typeof serializedAgentDescription>;
