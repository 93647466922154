export function isDarkColor(color: string): boolean {
  const hex = color.replace("#", "");
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);
  // Using the luminance formula to find brightness
  const brightness = 0.299 * r + 0.587 * g + 0.114 * b;
  return brightness < 128;
}

export function formatMessageText(text: string, isCharMessageDark: boolean) {
  let formattedText = text.replace(/\n/g, "<br>");
  const className = isCharMessageDark ? "lighten-italic" : "darken-italic";
  formattedText = formattedText.replace(
    /\*(.*?)\*/g,
    `<span class='${className}'>$1</span>`
  );
  return formattedText;
}

export function reverseFormatMessageText(htmlText: string): string {
  let unformattedText = htmlText.replace(/<br>/g, "\n");
  // Adjusted to dynamically match the specific <span> tag with either 'darken-italic' or 'lighten-italic' class
  unformattedText = unformattedText.replace(
    /<span class='(darken-italic|lighten-italic)'>(.*?)<\/span>/g,
    "*$2*"
  );
  return unformattedText;
}

export const getUtcTimestamp = async (): Promise<string> => {
  const servers = [
    "timeapi.io/api/time/current/zone?timeZone=UTC",
    "worldtimeapi.org/api/timezone/Etc/UTC",
    "worldtimeapi.org/api/timezone/America/New_York",
    "worldtimeapi.org/api/timezone/Europe/London",
  ];
  const timeout = new Promise((_, reject) =>
    setTimeout(() => reject(new Error("Request timed out")), 2000)
  );

  async function fetchWithTimeout(resource: any, options: any) {
    const { timeout = 2000 } = options;

    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    try {
      const response = await fetch(resource, {
        ...options,
        signal: controller.signal,
      });
      clearTimeout(id);
      return response;
    } catch (error) {
      clearTimeout(id);
      throw error;
    }
  }
  for (const server of servers) {
    try {
      const response = (await Promise.race([
        fetchWithTimeout(`https://${server}`, { timeout: 2000 }),
        timeout,
      ])) as Response;

      const data = await response.json();
      const serverDate = new Date(data.utc_datetime || "");
      console.log(`Fetched time from ${server}:`, serverDate.toISOString());
      return serverDate.toISOString();
    } catch (error) {
      console.warn(`Failed to fetch time from ${server}:`, error);
    }
  }
  console.error("Failed to fetch network time, using local time");
  return new Date().toISOString();
};
